import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Coding is my passion 😎. I'm a 🐕 lover! I'm a pretty fast learner and always interested in learning new technologies 🤓💡. I think one of my values is the ability to solve problems 🤝. I like to share what I know 👨‍🏫. In my non-coding hours, I'm at the 🏋‍ or 🏃‍♂️ or 😴 (my favorite!). I also do small business technology consultations 🏢.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      