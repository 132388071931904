import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Richard Dominguez.`}</h1>
    <p>{`I'm a Software Engineer who loves working with `}<span style={{
        color: "#61dafb",
        textShadow: "1px 1px grey"
      }}>React</span>, <span style={{
        color: "#6600cc",
        textShadow: "1px 1px grey"
      }}>Gatsby</span>, <span style={{
        color: "#61dafb",
        textShadow: "1px 1px grey"
      }}>React-Native</span>, <span style={{
        color: "#ff6d6d",
        textShadow: "1px 1px grey"
      }}>React-Spring</span>, <span style={{
        color: "#0073aa",
        textShadow: "1px 1px grey"
      }}>WordPress</span>, <span style={{
        color: "#008060",
        textShadow: "1px 1px grey"
      }}>Shopify</span>, <span style={{
        color: "silver",
        textShadow: "1px 1px #ffffff"
      }}>{`SquareSpace`}</span>{` and lots of other technologies! Currently in a Generalist Developer role with `}<a parentName="p" {...{
        "href": "https://thoughtcatalog.com/"
      }}>{`Thought & Expression Co`}</a>{`; responsible for `}<a parentName="p" {...{
        "href": "https://collective.world/"
      }}>{`Collective World`}</a>{`, among other projects. `}</p>
    <p>{`I'm also available for freelance work and business technology consulatations, just `}<a parentName="p" {...{
        "href": "mailto:RRJDominguez@gmail.com"
      }}>{`reach out`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      